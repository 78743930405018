var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-md-6"},[_c('fg-input',{attrs:{"placeholder":"Cerca Username","addon-left-icon":"ti ti-search","keyUpSearch":true},on:{"input-search":function($event){return _vm.asyncFindString($event)}},model:{value:(_vm.p_search),callback:function ($$v) {_vm.p_search=$$v},expression:"p_search"}})],1),_c('div',{staticClass:"col-md-6 text-right"},[(_vm.user_create)?_c('router-link',{attrs:{"to":{ name: 'User', params: { id: 'new', path: _vm.table1.route } }}},[_c('p-button',{attrs:{"addon-left-icon":"fa fa-plus","type":"success"}},[_vm._v("Aggiungi")])],1):_vm._e()],1)]),_c('div',{staticClass:"card"},[_c('vue-good-table',{ref:"generic-table",attrs:{"mode":"remote","styleClass":"vgt-table striped","totalRows":_vm.totalRecords,"title":"Generic Table","columns":_vm.columns,"rows":_vm.rows,"totalRecords":"","paginate":true,"lineNumbers":false,"pagination-options":{
        enabled: true,
        position: 'top',
        nextLabel: 'Successivo',
        prevLabel: 'Precedente',
        rowsPerPageLabel: 'Righe per pagina',
        dropdownAllowAll: false,
        perPage: _vm.myPerPage,
        perPageDropdown: _vm.myPerPageDropdown,
      },"sort-options":{ enabled: true },"selectOptions":{
        enabled: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'generic-class',
        selectionText: 'righe selezionate',
        clearSelectionText: 'deseleziona',
      },"isLoading":_vm.myLoading},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',[_c('action-table',{attrs:{"name":"User","path":_vm.table1.route,"id":props.row.id}})],1):(props.column.field == 'active')?_c('span',[(props.row.active == '1')?_c('span',{staticClass:"btn btn-success btn-sm",attrs:{"title":"clicca per disattivare l'utente"},on:{"click":function($event){return _vm.changeStatusUser(props.row.id)}}},[_c('span',{staticClass:"fa fa-check"})]):_c('span',{staticClass:"btn btn-danger btn-sm",attrs:{"title":"clicca per attivare l'utente"},on:{"click":function($event){return _vm.changeStatusUser(props.row.id)}}},[_c('span',{staticClass:"fa fa-times-circle"})])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"emptystate","id":"emptystate"},slot:"emptystate"},[_vm._v(" "+_vm._s(_vm.emptystate_msg)+" ")]),_c('div',{attrs:{"slot":"selected-row-actions"},slot:"selected-row-actions"},[(_vm.user_delete)?_c('a',{on:{"click":function($event){return _vm.deleteAll()}}},[_c('p-button',{attrs:{"type":"primary"}},[_vm._v("Cancella Selezionati")])],1):_vm._e(),_c('a',{on:{"click":function($event){return _vm.exportSelected()}}},[_c('p-button',{attrs:{"type":"primary"}},[_vm._v("Esporta Selezionati")])],1),_c('a',{on:{"click":function($event){return _vm.exportAll()}}},[_c('p-button',{attrs:{"type":"primary"}},[_vm._v("Esporta Tutti")])],1)])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }