<template>
    <div class="row">
         <div class="col-xl-12 col-lg-12 col-md-12">
            <edit-profile-form></edit-profile-form>
        </div>
    </div>
</template>

<script>
    import EditProfileForm from "./EditProfileForm.vue";

    export default {
        components: {
            EditProfileForm
        }
    };
</script>
