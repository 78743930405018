import
{
    FormGroupInput,
    Card,
    DropDown,
    Button,
    ActionListTableComponent,
    DraggableComponent,
    RelatedLanguages,
    RelatedCategoryMedia,
    RelatedCategoryVersioneMedia



} from "../components/index";

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
    install(Vue) {
        Vue.component("fg-input", FormGroupInput);
        Vue.component("drop-down", DropDown);
        Vue.component("card", Card);
        Vue.component("p-button", Button);
        Vue.component("action-table", ActionListTableComponent);        
        Vue.component("altrama-dragable", DraggableComponent);


        Vue.component('related-languages', RelatedLanguages);
        Vue.component('related-category-media', RelatedCategoryMedia);
        Vue.component('related-category-versione-media', RelatedCategoryVersioneMedia);
    }
};

export default GlobalComponents;
