<template>
  <table style="width:100%; height: 100px;">
  <tbody>
    <tr>
      <td class="align-middle"><h2>Logout in corso...</h2></td>
    </tr>
  </tbody>
</table>
</template>
<script>
export default {
  created: function() {
      this.$api.user.logout();
  }
};
</script>
