<template>
    <card title="Il mio Profilo">
        <loader :show="isLoading" :width="40" :height="40"></loader>
        <div v-if="entity">
            <form @submit.prevent="validateBeforeSubmit" novalidate autocomplete="off">
                <div class="row">
                    <div class="col-md-6">
                        <label>Nome</label>
                        <input autocomplete="off" type="text" :class="{ 'input form-control': true }"
                               name="nome" placeholder="" v-model="entity.first_name"/>
                        <p class="text-danger" v-if="errors.has('nome')">{{ errors.first('nome') }}</p>
                    </div>

                    <div class="col-md-6">
                        <label>Cognome</label>
                        <input autocomplete="off" type="text" :class="{ 'input form-control': true }"
                               name="cognome" placeholder="" v-model="entity.last_name"/>
                        <p class="text-danger" v-if="errors.has('cognome')">{{ errors.first('cognome') }}</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <label>Username</label>
                        <input autocomplete="off" disabled="disabled" type="text" :class="{ 'input form-control': true }" name="username"
                               placeholder="" v-model="entity.username"/>
                    </div>
                    <div class="col-md-6">
                        <label>Email</label>
                        <input autocomplete="off" type="email" v-validate="'required'" :class="{'input form-control': true }" name="email"
                               placeholder="" data-vv-validate-on="blur" v-model="entity.email"/>
                        <p class="text-danger" v-if="errors.has('email')">{{ errors.first('email') }}</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <label>Password</label>
                        <input autocomplete="off" name="password" ref="password" :class="{'input form-control': true }" type="password"
                               placeholder="**********" data-vv-validate-on="blur" v-model="entity.password">
                        <p class="text-danger" v-if="errors.has('password')">{{ errors.first('password') }}</p>
                    </div>
                    <div class="col-md-6">
                        <label>Conferma Password</label>
                        <input autocomplete="off"
                        v-validate="'confirmed:password'" :class="{'input form-control': true }"
                               name="conferma_password" type="password" placeholder="**********"
                               data-vv-validate-on="blur" v-model="entity.password_confirmation">
                        <p class="text-danger" v-if="errors.has('conferma_password')">{{
                            errors.first('conferma_password') }}</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <label>Ruolo</label>
                        <input :class="{'input form-control': true }"
                               name="role"
                               ref="role"
                               v-model="Object.values(entity.roles)[0]"
                               v-if="entity.roles"
                               readonly
                        >
                    </div>
                </div>

                <div class="text-center">
                    <button class="btn btn-success" :disabled="errors.any()">Aggiorna</button>
                    <p-button type="basic" @click.native.prevent="back">
                        CHIUDI
                    </p-button>
                </div>
                <br/>
                <div class="clearfix"></div>

            </form>
        </div>
    </card>
</template>

<script>
    import axios from 'axios';

    export default {
        data() {
            return {
                entity: false,
                user: {},
                isLoading: true
            };
        },
        mounted: function () {
            var self = this;
            axios({
                method: "get",
                url: self.$url + "user/" + this.$api.user.current().id,
                headers: this.$config.axiosHeaders(),
                params: {
                    id: this.id
                }
            })
                .then(function (response) {
                    self.entity = response.data.data;                   
                    self.isLoading = false;
                })
                .catch(function (error) {
                    self.$processError(error);
                });
        },
        methods: {
            validateBeforeSubmit() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        this.update();                        
                    }
                });
            },
            update() {

                var self = this;
                this.path = this.$route.params.path;
                var user = this.$api.user.current();
                self.entity.active = 1; //se modifica il proprio profilo sicuramente è attivo
                self.isLoading = true;
                axios({
                    method: 'put',
                    url: this.$url + 'user/' + user.id,
                    headers: this.$config.axiosHeaders(),
                    data: self.entity
                }).then(function (response) {                    
                    self.$notifyVue('top', 'center', response.data.status, response.data.data.message, "ti-check");
                    //Aggiorno le info in locale
                    user.first_name = self.entity.first_name;
                    user.last_name = self.entity.last_name;
                    user.username = self.entity.username;
                    user.email = self.entity.email;
                    localStorage.setItem("user", JSON.stringify(user));      
                    self.isLoading = false;              
                }).catch(function (error) {
                    self.isLoading = false;
                    self.$processError(error);
                });
            },
            back() {
                this.$router.go(-1);
            },
        }
    };
</script>
