<template>
  <div>
    <div class="row no-gutters">
      <div class="col-md-6">
        <fg-input
          placeholder="Cerca Username"
          addon-left-icon="ti ti-search"
          v-model="p_search"
          :keyUpSearch="true"
          v-on:input-search="asyncFindString($event)"
        >
        </fg-input>
      </div>
      <div class="col-md-6 text-right">
        <router-link
          :to="{ name: 'User', params: { id: 'new', path: table1.route } }"
          v-if="user_create"
        >
          <p-button addon-left-icon="fa fa-plus" type="success"
            >Aggiungi</p-button
          >
        </router-link>
      </div>
    </div>
    <div class="card">
      <vue-good-table
        ref="generic-table"
        mode="remote"
        styleClass="vgt-table striped"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        title="Generic Table"
        :columns="columns"
        :rows="rows"
        totalRecords
        :paginate="true"
        :lineNumbers="false"
        :pagination-options="{
          enabled: true,
          position: 'top',
          nextLabel: 'Successivo',
          prevLabel: 'Precedente',
          rowsPerPageLabel: 'Righe per pagina',
          dropdownAllowAll: false,
          perPage: myPerPage,
          perPageDropdown: myPerPageDropdown,
        }"
        :sort-options="{ enabled: true }"
        :selectOptions="{
          enabled: true,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'generic-class',
          selectionText: 'righe selezionate',
          clearSelectionText: 'deseleziona',
        }"
        :isLoading="myLoading"
      >
        <div slot="emptystate" id="emptystate">
          {{ emptystate_msg }}
        </div>

        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'action'">
            <action-table
              name="User"
              :path="table1.route"
              :id="props.row.id"
            ></action-table>
          </span>
          <span v-else-if="props.column.field == 'active'">
            <span
              v-if="props.row.active == '1'"
              title="clicca per disattivare l'utente"
              class="btn btn-success btn-sm"
              @click="changeStatusUser(props.row.id)"
            >
              <span class="fa fa-check"></span>
            </span>
            <span
              v-else
              title="clicca per attivare l'utente"
              class="btn btn-danger btn-sm"
              @click="changeStatusUser(props.row.id)"
            >
              <span class="fa fa-times-circle"></span>
            </span>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <div slot="selected-row-actions">
          <a v-on:click="deleteAll()" v-if="user_delete">
            <p-button type="primary">Cancella Selezionati</p-button>
          </a>
          <a v-on:click="exportSelected()">
            <p-button type="primary">Esporta Selezionati</p-button>
          </a>
          <a v-on:click="exportAll()">
            <p-button type="primary">Esporta Tutti</p-button>
          </a>
        </div>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const tableColumns = ["id", "cognome", "email"];
var tableData = [];
var self = this;

export default {

  data() {
    return {
      table1: {
        title: "Elenco Utenti",
        subTitle: "",
        columns: [...tableColumns],
        data: [...tableData],
        route: "user",
        classe: "utente-class",
        messaggio: "",
        see_msg: false,
      },

      columns: [
        {
          label: "ID",
          field: "id",
          type: "number",
          html: false,
          tdClass: "text-center",
          thClass: "text-center",
          sortable: true,
        },
        {
          label: "USERNAME",
          field: "username",
          filterable: true,
          sortable: true,
        },
        {
          label: "NOME",
          field: "name",
          filterable: true,
          sortable: false,
        },
        {
          label: "REDAZIONE",
          field: "redazione",
          filterable: true,
          sortable: false,
        },
        {
          label: "STATO",
          field: "active",
          filterable: false,
          sortable: false,
          html: true,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "AZIONI",
          field: "action",
          filterable: false,
          sortable: false,
          html: true,
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      user_create: false,
      user_delete: false,
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "id",
          type: "desc",
        },
        page: 1,
        limit: 25,
      },
      name: "my-table",
      p_search: "",
      emptystate_msg: "",
      myPerPageDropdown: [10, 20, 30, 40, 50],
      myPerPage: 50,
      myCurrentPage: 1,
      myLoading: false,
      myVarTimeout: "",
    };
  },
  beforeDestroy: function () {},
  beforeMount: function () {},
  beforeCreate: function () {},

  created: async function () {
    this.user_create = await this.$api.user.can("user-create");
    this.user_delete = await this.$api.user.can(this.path + "-delete");

    var navigationInfo =
      this.$getNavigationTable() &&
      this.$getNavigationTable()[this.table1.route]
        ? this.$getNavigationTable()[this.table1.route].split("_")
        : null;
    if (navigationInfo != null && navigationInfo[0] == this.table1.route) {
      this.serverParams.page = Number(navigationInfo[1]);
      this.serverParams.perPage = Number(navigationInfo[2]);
      this.serverParams.limit = Number(navigationInfo[2]);
      this.serverParams.sort.field = navigationInfo[3];
      this.serverParams.sort.type = navigationInfo[4];
      this.myPerPage = Number(navigationInfo[2]);
    } else {
      this.serverParams.page = 1;
      this.serverParams.perPage = this.myPerPage;
    }

    this.updateResource(this.serverParams.page, this.serverParams.perPage);
  },

  mounted: async function () {
    // verifica in fase di mounted se siamo autenticati
    if (!await this.$api.user.can("user-view")) {
      this.$router.replace("/not-found");
    }
  },
  methods: {
    updateResource(page_start, per_page) {
      var self = this;
      self.rows = [];
      self.emptystate_msg = "";
      self.myLoading = true;

      var typeSort = "+";
      if (this.serverParams.sort.type == "desc") typeSort = "-";

      this.$api.user
        .index({
          page: page_start,
          limit: per_page, // se omesso di default 25
          orderBy: typeSort + this.serverParams.sort.field,
        })
        .then((data) => {
          if (data.meta.total == 0) {
            self.emptystate_msg = "Nessun risultato trovato";
          }

          self.totalRecords = data.meta.total;
          self.rows = [];
          for (let utente of data.data) {
            self.rows.push({
              id: utente.id,
              username: utente.username,
              name: utente.first_name + " "  + utente.last_name  ,
              redazione: utente.redazione,
              email: utente.email,
              active: utente.active,
            });
          }
          self.see_msg = true;
        })
        .finally(() => {
          self.myLoading = false;
        });

      // rivedi. la checkbox selezionatutti dopo update deve essere false
      var x = (document.getElementsByClassName(
        "vgt-checkbox-col"
      ).checked = false);
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
      this.$setNavigationInfo(
        this.table1.route,
        this.serverParams.page,
        this.serverParams.perPage,
        this.serverParams.sort.field,
        this.serverParams.sort.type
      );
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems(params.currentPage, params.currentPerPage);
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems(params.currentPage, params.currentPerPage);
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
      this.loadItems(params.currentPage, params.currentPerPage);
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems(params.currentPage, params.currentPerPage);
    },

    loadItems(page, per_page) {
      this.updateResource(page, per_page);
    },

    deleteAll: function () {
      if (confirm("Confermare cancellazione degli elementi selezionati?")) {
        var elenco = [];
        var selected = this.$refs["generic-table"].selectedRows;
        for (var i = 0; i < selected.length; i++) {
          elenco.push(selected[i].id);
        }
        var self = this;

        axios({
          method: "delete",
          url: this.$url + this.table1.route + "/" + elenco,
          headers: this.$config.axiosHeaders(),
          params: {
            id: elenco,
          },
        })
          .then(function (response) {
            var color = 2;
            var icon = "ti-check";

            if (response.data.status == "success") {
              self.loadItems(self.serverParams.page, self.serverParams.limit);
            } else {
              color = 3;
              icon = "ti-close";
            }
            self.$notifyVue(
              "top",
              "center",
              "success",
              response.data.data.message,
              icon
            );
          })
          .catch(function (error) {
            self.$notifyVue(
              "top",
              "center",
              "success",
              response.data.message,
              icon
            );
            self.$processError(error);
          });
      } // confirm
    },

    exportAll: function () {
      var self = this;

      axios({
        method: "get",
        url: this.$url + this.table1.route + "/export",
        headers: this.$config.axiosHeaders(),
      })
        .then(function (response) {
          var blob = new Blob([response.data], {
            type: "text/csv;charset=utf-8;",
          });
          if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
          } else {
            var link = document.createElement("a");
            if (link.download !== undefined) {
              // feature detection
              var url = URL.createObjectURL(blob);
              link.setAttribute("href", url);
              link.setAttribute("download", self.table1.route + ".csv");
              link.style.visibility = "hidden";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
        })
        .catch(function (error) {
          self.$processError(error);
        });
    },

    exportSelected: function () {
      var elenco = [];
      var selected = this.$refs["generic-table"].selectedRows;

      for (var i = 0; i < selected.length; i++) {
        elenco.push(selected[i].id);
      }
      var self = this;

      axios({
        method: "get",
        url: this.$url + this.table1.route + "/export" + "/" + elenco,
        headers: this.$config.axiosHeaders(),
        params: {
          id: elenco,
        },
      })
        .then(function (response) {
          var blob = new Blob([response.data], {
            type: "text/csv;charset=utf-8;",
          });
          if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
          } else {
            var link = document.createElement("a");
            if (link.download !== undefined) {
              // feature detection
              // Browsers that support HTML5 download attribute
              var url = URL.createObjectURL(blob);
              link.setAttribute("href", url);
              link.setAttribute("download", self.table1.route + ".csv");
              link.style.visibility = "hidden";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
        })
        .catch(function (error) {
          self.$processError(error);
        });
    },

    search(e) {
      var self = this;

      self.emptystate_msg = "";
      self.myLoading = true;
      if (self.p_search.length > 0) {
        axios({
          method: "post",
          url: this.$url + this.table1.route + "/search",
          headers: this.$config.axiosHeaders(),
          params: {
            keywords: self.p_search,
          },
        })
          .then(function (response) {
            if (response.data.status == "success") {
              self.rows = [];
              self.totalRecords = response.data.meta.total;
              if (response.data.meta.total == 0) {
                self.emptystate_msg = "Nessun risultato trovato";
              }
              var obj = response.data.data;
              for (let utente of obj) {
                self.rows.push({
                  id: utente.id,
                  username: utente.username,
                  last_name: utente.last_name,
                  first_name: utente.first_name,
                  redazione: utente.redazione,
                  email: utente.email,
                  roles: utente.roles,
                  active: utente.active,
                });
              }
              self.see_msg = true;
              self.myLoading = false;
            }
          })
          .catch(function (error) {
            console.error(error);
          });
      } else {
        this.loadItems(this.serverParams.page, this.serverParams.limit);
      }
    },
    changeStatusUser(id) {
      var self = this;

      self.myLoading = true;

      axios({
        method: "post",
        url: this.$url + this.table1.route + "/change_status",
        headers: this.$config.axiosHeaders(),
        params: {
          id: id,
        },
      })
        .then(function (response) {
          if (response.data.status == "success") {
            self.$notifyVue(
              "top",
              "center",
              response.data.status,
              response.data.data.message,
              "ti-check"
            );
            self.see_msg = true;
            self.loadItems(self.serverParams.page, self.serverParams.limit);
            self.myLoading = false;
          }
        })
        .catch(function (error) {
          self.$processError(error);
        });
    },

    asyncFindString: function () {
      if (this.p_search.length >= 2) {
        self = this;
        clearTimeout(self.myVarTimeout); //annullo attesa precedente
        self.myVarTimeout = setTimeout(function () {
          self.search(self.p_search);
        }, 1000);
      } //controllo lunghezza stringa
      else if (this.p_search.length == 0) this.updateResource();
    },
  }, // methods
};
</script>
