<template>
  <div class="editor-page">
    <loader v-if="item==null" :show="item==null" :width="40" :height="40"></loader>
    <top-bar
        v-if="item!=null"
        :title="id=='new' ? 'Inserimento Redazione' : 'Modifica Redazione   '"
        :action="`Salva`"
        :actionIsValid="isValid"
        @onAction="save"
    ></top-bar>
    <form :class="['form', 'row']" novalidate v-if="item!=null" @submit.prevent="submit">
      <div class="col-12">
        <card title="Redazione" :collapse="false">
          <content>
            <textbox
                title="Nome"
                :v="$v.item.nome"
                v-model="item.nome"
            />
            <textbox
                title="URL italiano"
                :v="$v.item.url_it"
                v-model="item.url_it"
            />
            <textbox
                title="URL inglese"
                :v="$v.item.url_en"
                v-model="item.url_en"
            />
          </content>
        </card>
        <card v-if="item.id !== 'new'" title="Utenti di Redazione">
          <table class="table table-borderless border-top-0">
            <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">nome</th>
              <th scope="col">username</th>
              <th scope="col">email</th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tr v-for="(user, user_index) in item.users">
              <th scope="row">{{ user_index + 1 }}</th>
              <td>{{ user.first_name + ' ' + user.last_name }}</td>
              <td>{{ user.username }}</td>
              <td>{{ user.email }}</td>
              <td>
                <span v-if="user.active==1" class="badge badge-pill badge-success">attivo</span>
                <span v-else class="badge badge-pill badge-secondary">non attivo</span>
              </td>
            </tr>
          </table>
        </card>

        <card v-if="item.id !== 'new'" title="Comuni associati a Redazione">
          <div class="row">
            <div class="col-6">
              <comune-istat-box v-model="comune_istat"></comune-istat-box>
            </div>
            <div class="col-6">
              <button v-if="comune_istat != null" class="btn btn-outline-success" @click.prevent="addRow">Aggiungi <span v-if="isLoading"><i class="fa fa-spinner"></i></span></button>
            </div>
          </div>
          <table class="table table-bordered">
            <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">comune</th>
              <th scope="col">provincia</th>
              <th scope="col">codice</th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tr v-for="(comune, index) in item.comuni_istat" :key="index" :row="comune">
              <th scope="row">{{ index + 1 }}</th>
              <td>{{ comune.nome }}</td>
              <td>{{ comune.provincia.nome }}</td>
              <td>{{ comune.codice }}</td>
              <td @click.prevent="deleteRedazioneComune(comune, index)">
                <span class="btn-sm btn-danger">
                    <i class="fa fa-trash"></i>
                </span>
              </td>
            </tr>
          </table>
        </card>
      </div>
      <div class="col-md-3"><!-- SIDEBAR DX -->

      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";

import Textbox from "../../components/altrama/form/Textbox";
import TopBar from "../../components/altrama/TopBarFlat";
import ComuneIstatBox from "@/components/altrama/section/users/ComuneIstatBox";

import {validationMixin} from "vuelidate";
import {required, url} from "vuelidate/lib/validators";

export default {
  components: {
    TopBar,
    Textbox,
    ComuneIstatBox
  },
  props: ["id"],
  mixins: [validationMixin],
  validations() {
    return {
      item: {
        nome: {required},
        url_it: {url},
        url_en: {url},
      }
    };
  },
  data() {
    return {
      needSave: false,
      hasErrors: false,
      item: null,
      lang: "it",
      isLoading: false,
      comune_istat: null
    };
  },
  computed: {
    isValid: function () {
      return true;
    },
  },
  methods: {
    template: function () {
      return {
        id: "new",
        nome: "",
        users: [],
      };
    },
    addRow: function () {
      let self = this
      self.isLoading = true

      let comuni_istat_id = self.comune_istat.id
      let redazioni_id = self.id
      let comune = self.comune_istat

      axios({
        method: 'POST',
        url: this.$url + 'operations/redazione/' + redazioni_id + '/comune/' + comuni_istat_id,
        headers: this.$config.axiosHeaders(),
      }).then(function (response) {
        self.$notifyVue('top', 'center', 'success', 'comune aggiunto', 'ti-check');
         self.item.comuni_istat.push({
           codice: comune.istat,
           nome: comune.city,
           provincia: {
             nome: comune.province
           },
           pivot: {
             comuni_istat_id: comuni_istat_id,
             redazioni_id: redazioni_id
           }
         });
      }).catch(function (error) {

      }).finally(
          () => self.isLoading = false
      );
    },
    save() {
      this.$v.$touch();
      if (!this.$v.item.nome.$anyError) {
        let self = this;
        let url = `${self.$url}user/redazioni/${self.id}`;
        let method = "put";

        if (this.item.id == "new") {
          method = 'post';
          url = `${self.$url}user/redazioni`;
        }

        this.item.lang = this.lang;
        axios({
          method: method,
          url: url,
          headers: this.$config.axiosHeaders(),
          data: this.item
        })
            .then(function (response) {
              self.$notifyVue(
                  "top",
                  "center",
                  response.data.status,
                  response.data.data.message,
                  "ti-check"
              );
              self.item.id = response.data.data.id;
              self.$router.push({ params: { id: self.item.id } });
            })
            .catch(function (error) {
              self.hasErrors = true;
              self.$processError(error);

            });

        this.needSave = false;
      }
    },
    loadData: function () {
      let self = this;

      if (this.id == "new") {
        self.item = Object.assign({}, self.template());
        self.needSave = false;
        return;
      }
      axios({
        method: "get",
        url: `${self.$url}user/redazioni/${self.id}`,
        headers: this.$config.axiosHeaders()
      })
          .then(function (response) {
            self.item = _.merge(self.template(), response.data.data);
            self.needSave = false;
          })
          .catch(function (error) {
            self.hasErrors = true;
            self.$processError(error);

          });
    },
    deleteRedazioneComune: function (comune, index) {
      console.log('INDEX:' +index)
      const self = this

      if (confirm('cancellare associazione con ' + comune.nome + '?')) {
        self.item.comuni_istat.splice(index, 1);

        let comuni_istat_id = comune.pivot.comuni_istat_id
        let redazioni_id = comune.pivot.redazioni_id
        let comune_istat = comune.nome

        axios({
          method: 'DELETE',
          url: this.$url + 'operations/redazione/' + redazioni_id + '/comune/' + comuni_istat_id,
          headers: this.$config.axiosHeaders(),
        }).then(function (response) {
          self.$notifyVue('top', 'center', 'success', 'cancellato ' + comune_istat, 'ti-check');
        }).catch(function (error) {
          self.$processError(error);

        })
      }
    },
  },
  mounted: function () {
    this.loadData();
  },
  watch: {
    id: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.loadData();
      }
    }
  }
};
</script>
