<template>
    <div>
        <div class="row">
            <div class="col-lg-4 text-right">
                <router-link :to="{ name: 'RedazioneDetail', params: {id:'new'} }" v-if="entity_create">
                    <button class="btn btn-success ml-2">
                        <i class="ti ti-plus"></i> Nuova Redazione
                    </button>
                </router-link>
            </div>
        </div>
        <br/>
        <div class="card">
            <vue-good-table
                    ref="generic-table"
                    mode="remote"
                    styleClass="vgt-table striped"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-per-page-change="onPerPageChange"
                    :totalRows="totalRecords"
                    title="Redazioni"
                    :columns="columns"
                    :rows="rows"
                    totalRecords
                    :paginate="true"
                    :lineNumbers="false"
                    :pagination-options="{
                                enabled: true,
                                position: 'top',
                                nextLabel: 'Successivo',
                                prevLabel: 'Precedente',
                                rowsPerPageLabel: 'Righe per pagina',
                                dropdownAllowAll: false,
                                perPage: serverParams.pagination.currentPerPage,
                                perPageDropdown: serverParams.myPerPageDropdown,
                                setCurrentPage: serverParams.page
                    }"
                    :sort-options="{ enabled: true, }"
                    :isLoading="isLoading"
            >
                <div slot="emptystate" id="emptystate" v-if="totalRecords == 0">
                    <div class="vgt-center-align vgt-text-disabled">
                        <span v-if="!isLoading">Nessun risultato disponibile</span>
                    </div>
                </div>
                <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field == 'action'">
                        <action-table name="RedazioneDetail" :path="table1.route" :id="props.row.id"></action-table>
                      </span>
                </template>
            </vue-good-table>
            <!-- chiusura componente -->
        </div>
    </div>
</template>

<script>
    import axios from "axios";

    // import SearchBox from "../../components/altrama/SearchBox";

    const tableColumns = [];
    var tableData = [];
    var self = this;

    export default {
        components: {

            // SearchBox,
        },
        data() {
            return {
                table1: {
                    title: "Elenco",
                    columns: [...tableColumns],
                    data: [...tableData],
                    route: "redazioni"
                },
                columns: [
                    {
                        label: "ID",
                        field: "id",
                        type: "number",
                        html: false,
                        tdClass: "text-center",
                        thClass: "text-center"
                    },
                    {
                        label: "TITOLO",
                        field: "title",
                        sortable: false
                    },
                    {
                        label: "UTENTI",
                        field: "users",
                        tdClass: "text-left",
                        thClass: "text-left",

                        sortable: false
                    },
                    {
                        label: "AZIONI",
                        field: "action",
                        html: true,
                        tdClass: "text-center",
                        thClass: "text-center",
                        sortable: false
                    }
                ],
                entity_create: false,
                entity_delete: false,
                rows: [],
                totalRecords: 0,
                serverParams: {
                    columnFilters: {},
                    sort: {
                        field: "id",
                        type: "desc"
                    },
                    pagination: {
                        currentPage: 1,
                        currentPerPage: 50
                    },
                    myPerPageDropdown: [10, 20, 30, 40, 50]
                },
                isLoading: false,
                searchQuery: {},
                langs:this.$available_langs_code
            };
        },

        created: async function () {

            if ( await this.$api.user.can( "redazioni-view") == false) {
                this.$router.replace("/not-found");
                return;
            }

            this.entity_create = await this.$api.user.can("redazioni-create");
            this.entity_delete = false;
            this.searchQuery = JSON.parse(
                localStorage.getItem("redazioni_searchQuery")
            ) || this.searchQuery;
            this.serverParams = JSON.parse(localStorage.getItem("redazioni_serverParams")) || {pagination: {}, sort: {}};

            this.updateResource();
        },

        methods: {
            filter() {
                this.serverParams.pagination.currentPage = 1;
                this.updateResource();
            },

            noFilter() {
                localStorage.removeItem("redazioni_searchQuery");
                localStorage.removeItem("redazioni_serverParams");

                this.serverParams = {
                    columnFilters: {},
                    sort: {
                        field: "id",
                        type: "desc"
                    },
                    pagination: {
                        currentPage: 1,
                        currentPerPage: 50
                    },
                    myPerPageDropdown: [10, 20, 30, 40, 50]
                };
                this.searchQuery = {};
                this.updateResource();
            },

            saveParameterFilter() {
                localStorage.setItem(
                    "redazioni_searchQuery",
                    JSON.stringify(this.searchQuery)
                );
                localStorage.setItem(
                    "redazioni_serverParams",
                    JSON.stringify(this.serverParams)
                );
            },

            async updateResource() {
                var self = this;
                self.rows = [];
                self.isLoading = true;

                var typeSort = "+";
                if (this.serverParams.sort.type == "desc") {
                    typeSort = "-";
                }

                this.saveParameterFilter();

                await axios({
                    method: "GET",
                    url: self.$url + 'user/' + this.table1.route,
                    headers: self.$config.axiosHeaders(),
                    params: {
                        orderBy: typeSort + self.serverParams.sort.field,
                        page: self.serverParams.pagination.currentPage,
                        limit: self.serverParams.pagination.currentPerPage
                    }
                })
                    .then(function (response) {
                        self.totalRecords = response.data.total;
                        self.rows = [];
                        var obj = response.data.data;
                     for( let entity of obj){

                            self.rows.push({
                                id: entity.id,
                                title: entity.nome,
                                users: entity.users_count,
                            });
                        }
                    })
                    .catch(function (error) {
                        self.$processError(error);

                    })
                    .finally(() => (self.isLoading = false));
            },

            onPerPageChange(params) {
                this.serverParams.pagination = params;
                this.updateResource();
            },

            onPageChange(params) {
                this.serverParams.pagination = params;
                this.updateResource();
            },

            onSortChange(params) {
                this.serverParams.sort = params[0];
                this.updateResource();
            },
        } // methods
    };
</script>
