<template>
    <div class="hello">
        <div class="limiter">
            <div class="container-login100">
                <div class="wrap-login100">
                    <div class="login100-pic js-tilt" data-tilt>
                        <img :src="$branding.logoPositivo" alt="">
                    </div>
                    <form class="login100-form validate-form"  @submit.prevent="validateBeforeSubmit">
                        <span class="login100-form-title">
                           Recupero Credenziali
                        </span>
                        <span>
                            <div v-if="recovery_msg" class="error">
                                {{ msg_recupero_password }}
                            </div>
                        </span>
                        <div class="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                            <input  v-validate="'required|email'" class="input form-control" name="email" placeholder="Email" v-model="email"/>
                            <p class="text-danger" v-if="errors.has('email')">{{ errors.first('email') }}</p>
                            <span class="focus-input100"></span>
                            <span class="symbol-input100"></span>
                        </div>
                        <div class="container-login100-form-btn">
                            <button class="login100-form-btn" >   <!-- @click.prevent="doRecovery" -->
                                Invia Email
                            </button>
                        </div>
                        <div class="text-center p-t-12">
                            <span class="txt1">
							    Effettua
						    </span>

                            <a class="txt2" href="#" @click.prevent="login_again">
                                Login
                            </a>
                        </div>

                        <div class="text-center mt-2" v-if="request">
                               <img src="@/assets/img/spinner.gif" alt="">
                        </div>
                        
                    </form>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        name: 'RecuperaPassword',
        data() {

            return {
                recovery_msg: false,
                email: "",
                msg_recupero_password: "",
                request:false,
            }
        },
        props: {},
        methods: {
            login_again: function () {
                this.changeStatus(false);
            },

            doRecovery: function (index) {


                var esito = false;
                var self = this;
                this.request=true;
                //console.log(self.request);
                this.recovery_msg=false;
                this.msg_recupero_password="";


                axios.post(this.$url + 'password/reset/email', {
                    email: self.email
                })
                .then(function (response) {
                    self.msg_recupero_password = response.data.data.message;
                    self.recovery_msg = true;
                    self.request=false;
                })
                .catch(function (error) {
                    if (error.response.data.data !== undefined && error.response.data.data !== null) {
                        self.msg_recupero_password = error.response.data.data.message;
                        self.recovery_msg=true;
                    }
                    else {
                        for (var key in error.response.data.errors){
                            if(key == "exceptionCode" || key == "exceptionClass"){
                                break;
                            }
                            else{
                                self.msg_recupero_password = error.response.data.errors[key][0];
                                 self.recovery_msg = true;

                            }
                        }
                    }
                     self.request=false;
                });

               // this.request=false;

            },
            changeStatus: function (esito) {
                this.$emit('changeStatus', esito);
            },

            validateBeforeSubmit() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                      //alert(result);
                      this.doRecovery();
                        return;
                    }

                });
        },

        }
    };
</script>
<style scoped src="../assets/css/login.css"></style>
<style scoped src="../assets/css/util.css"></style>
