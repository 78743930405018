var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4 text-right"},[(_vm.entity_create)?_c('router-link',{attrs:{"to":{ name: 'RedazioneDetail', params: {id:'new'} }}},[_c('button',{staticClass:"btn btn-success ml-2"},[_c('i',{staticClass:"ti ti-plus"}),_vm._v(" Nuova Redazione ")])]):_vm._e()],1)]),_c('br'),_c('div',{staticClass:"card"},[_c('vue-good-table',{ref:"generic-table",attrs:{"mode":"remote","styleClass":"vgt-table striped","totalRows":_vm.totalRecords,"title":"Redazioni","columns":_vm.columns,"rows":_vm.rows,"totalRecords":"","paginate":true,"lineNumbers":false,"pagination-options":{
                            enabled: true,
                            position: 'top',
                            nextLabel: 'Successivo',
                            prevLabel: 'Precedente',
                            rowsPerPageLabel: 'Righe per pagina',
                            dropdownAllowAll: false,
                            perPage: _vm.serverParams.pagination.currentPerPage,
                            perPageDropdown: _vm.serverParams.myPerPageDropdown,
                            setCurrentPage: _vm.serverParams.page
                },"sort-options":{ enabled: true, },"isLoading":_vm.isLoading},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',[_c('action-table',{attrs:{"name":"RedazioneDetail","path":_vm.table1.route,"id":props.row.id}})],1):_vm._e()]}}])},[(_vm.totalRecords == 0)?_c('div',{attrs:{"slot":"emptystate","id":"emptystate"},slot:"emptystate"},[_c('div',{staticClass:"vgt-center-align vgt-text-disabled"},[(!_vm.isLoading)?_c('span',[_vm._v("Nessun risultato disponibile")]):_vm._e()])]):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }