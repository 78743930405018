<template>
  <div class="hello">
    <div class="limiter">
      <div class="container-login100">
        <div class="wrap-login100">
          <div class="login100-pic js-tilt" data-tilt>
            <img :src="$branding.logoPositivo" alt="" />
          </div>
          <form
            class="login100-form validate-form"
            autocomplete="on"
            @submit.prevent="validateBeforeSubmit"
          >
            <span class="login100-form-title"> Area Riservata </span>           
            <div
              class="wrap-input100 validate-input"
              data-validate="Valid email is required: ex@abc.xyz"
            >
              <input
                class="input100"
                type="text"
                id="username"
                name="username"
                v-model="username"
                placeholder="Username"
                autocomplete="on"
                v-validate="'required'"
              />
              <span class="focus-input100"></span>
              <span class="symbol-input100">
                <i class="fa fa-user" aria-hidden="true"></i>
              </span>              
            </div>
            <div
              class="password-wrapper wrap-input100 validate-input"
              data-validate="Password is required"
            >
              <input
                type="password"
                class="password input100"
                id="password"
                name="password"
                autocomplete="on"
                v-model="password"
                placeholder="Password"
              />
              <span class="focus-input100"></span>
              <span class="symbol-input100">
                <i class="fa fa-lock" aria-hidden="true"></i>
              </span>
            </div>
            
            <div class="container-login100-form-btn">
              <button :disabled="isLoading" class="login100-form-btn">
                <!-- @click.prevent="doLogin" -->
                Entra
                <img src="@/assets/img/spinner-white.gif" class="mx-4" alt="loading" height="20px" v-if="isLoading" />
              </button>
            </div>

            <div class="text-center p-t-12">
              <span class="txt1">Recupera</span>
              <router-link to="password/reset" class="txt2">
                Username / Password
              </router-link>
            </div>            
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {     
      username: "",
      password: "",
      isLoading: false,
      next: "/dashboard", // prossima rotta da visualizzare
    };
  },
methods :{
  validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.isLoading = true;
          this.$api.user.login(this.username, this.password).then((user) => { 
              if( user !== null ){
                this.$router.replace(this.next);
              }              
          }).finally(() => {
             this.isLoading = false;
          });          
        }
      });
    },
},
  created: async function () {  
    if (await this.$api.user.current() != null) {      
      this.$router.replace(this.next);
    }
  },
};
</script>

<style scoped src="../assets/css/login.css"></style>
<style scoped src="../assets/css/util.css"></style>
