<template>
    <div class="hello">
        <div class="limiter">
            <div class="container-login100">
                <div class="wrap-login100">
                    <div class="login100-pic js-tilt" data-tilt>
                        <img :src="$branding.logoPositivo" alt="">
                    </div>

                    <form class="login100-form validate-form" @submit.prevent="validateBeforeSubmit">
                        <span class="login100-form-title">
                            Reset Password
                        </span>

                        <span>
                            <div v-if="recupero_password_erros==true" class="error">
                                {{ msg_recupero_password }}
                            </div>
                        </span>




                        <div class="wrap-input100 validate-input" data-validate="Password is required">
                            <input class="input100"
                                    type="password"
                                    id="password_new_1"
                                    name="Password"
                                    v-validate="'required|min:8'" ref="password"
                                    v-model="password_new_1" placeholder="Password">
                            <span class="focus-input100"></span>
                            <span class="symbol-input100">
							<i class="fa fa-lock" aria-hidden="true"></i>
						</span>



                        </div>

                         <p class="text-danger" v-if="errors.has('Password')">{{errors.first('Password')}}</p>



                         <div class="wrap-input100 validate-input" data-validate="Password is required">
                            <input class="input100"
                                   type="password"
                                   id="password_new_2"
                                   name="Conferma Password"
                                   v-validate="'required|confirmed:password'"
                                   v-model="password_new_2"
                                   placeholder="Conferma Password">
                            <span class="focus-input100"></span>
                            <span class="symbol-input100">
							<i class="fa fa-lock" aria-hidden="true"></i>
						</span>




                        </div>

                         <p class="text-danger" v-if="errors.has('Conferma Password')">{{errors.first('Conferma Password') }}</p>







                        <div class="container-login100-form-btn">
                            <button class="login100-form-btn" >       <!-- @click.prevent="resetPassword" -->
                                Reset
                            </button>
                        </div>

                        <div class="text-center p-t-12">
						<span class="txt1">
							Vai al
						</span>
                            <a class="txt2" href="#/login">
                                Login
                            </a>
                        </div>

                         <div class="text-center mt-2" v-if="request">
                               <img src="@/assets/img/spinner.gif" alt="">
                        </div>                        
                    </form>
                </div>
            </div>
        </div>





    </div>
</template>

<script>
    import axios from 'axios';
    import Vue from 'vue'
    export default {
        name: 'ResetPassword',
        data() {

            return {
                recupero_password_erros:false,
                password_new_1:"",
                password_new_2:"",
                msg_recupero_password:"",
                request:false,

            }
        },
        props: ['code' ],
        methods: {

             login_again:function(){

                this.$router.replace("/login");
            },

            changeStatus: function (esito) {
                this.$emit('changeStatus', esito);
            },
            updatedPassword:function(esito){
                this.$emit('updatedPassword', esito);
            },

            resetPassword: function(esito){
                var code=this.$route.params.code;
                var self=this;
                self.request=true;
                this.recupero_password_erros=false;
                //console.log(code);


                                 axios({
                                    method: 'post',
                                    url: this.$url + 'password/reset/'+code,
                                  
                                    params: {
                                       email_token: this.$route.params.code,
                                       password: self.password_new_1,
                                       password_confirmation: self.password_new_2,
                                    }

                                }).then(function (response) {
                                        //console.log(response);
                                        if (response.data.code == '200' ) {
                                            //console.log(response)
                                            self.msg_recupero_password=response.data.data.message;
                                            self.recupero_password_erros=true;


                                        }
                                         self.request=false;

                                        }).catch(function (error) {
                                            //console.error(error.response);
                                            self.msg_recupero_password=error.response.data.data.message;
                                            //alert(error.response.data.data.message);
                                            //self.$notifyVue('top', 'center', "warning", response.data.data.message, "ti-close");
                                            self.recupero_password_erros=true;
                                            self.request=false;
                                        });


            },


            validateBeforeSubmit() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                      //alert(result);
                      this.resetPassword();
                        return;
                    }

                });
        },


        }
    };
</script>

<style scoped src="../assets/css/login.css"></style>
<style scoped src="../assets/css/util.css"></style>